import { graphql, useStaticQuery } from 'gatsby';
import AWSIcon from 'svgs/single-case-study/aws.svg';
import DataAnalysisIcon from 'svgs/single-case-study/data-analysis.svg';
import PythonIcon from 'svgs/single-case-study/python.svg';
import RIcon from 'svgs/single-case-study/r.svg';

import { contentResolver } from '../utilities';

const faqPath = 'faq-section.content';
const FAQ_ITEMS = [
    {
        question: `${faqPath}.q1`,
        answer: `${faqPath}.a1`,
    },
    {
        question: `${faqPath}.q2`,
        answer: `${faqPath}.a2`,
    },
    {
        question: `${faqPath}.q3`,
        answer: `${faqPath}.a3`,
    },
    {
        question: `${faqPath}.q4`,
        answer: `${faqPath}.a4`,
    },
    {
        question: `${faqPath}.q5`,
        answer: `${faqPath}.a5`,
    },
];

const content = contentResolver({
    project: 'churn',
    brandColor: '--churn-case-study',
    brandColorGradient: {
        start: '--churn-case-study-gradient-start',
        stop: '--churn-case-study-gradient-stop',
    },
    scopeOfWork: [
        {
            title: 'scopeOfWork.dataAnalysis',
            Icon: DataAnalysisIcon,
        },
        {
            title: 'scopeOfWork.r',
            Icon: RIcon,
        },
        {
            title: 'scopeOfWork.python',
            Icon: PythonIcon,
        },
        {
            title: 'scopeOfWork.awsCloud',
            Icon: AWSIcon,
        },
    ],
    projectInfo: {
        count: 2,
        dataIndexes: [0, 2],
        colors: ['--churn-case-study', '--churn-case-study-purple'],
    },
    paragraphsCounters: {
        header: 1,
        about: 1,
        challenge: 3,
        mainGoals: 3,
        solutions: 3,
        summary: 4,
    },
    caseStudies: [
        'moneyTransferApplication',
        'nomisSolutions',
        'salesPredictiveAlgorithm',
    ],
    caseStudiesSubtitle: 'case-studies.subtitle',
    faqTitle: 'faq-section.title',
    faqData: FAQ_ITEMS,
});

export const useChurn = () => {
    const images = useStaticQuery(
        graphql`
            query {
                headerImage: file(
                    relativePath: { eq: "single-case-study/churn/laptop.png" }
                ) {
                    ...ImageCaseStudyConfig
                }
                aboutImage: file(
                    relativePath: { eq: "single-case-study/churn/about.png" }
                ) {
                    ...ImageCaseStudyConfig
                }
                challengeImage: file(
                    relativePath: {
                        eq: "single-case-study/churn/challenge.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                mainGoalsImage: file(
                    relativePath: {
                        eq: "single-case-study/churn/main-goals.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                solutionsImage: file(
                    relativePath: {
                        eq: "single-case-study/churn/solutions.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                summaryImage: file(
                    relativePath: { eq: "single-case-study/churn/summary.png" }
                ) {
                    ...ImageCaseStudyConfig
                }
            }
        `,
    );

    return {
        data: { ...images, ...content },
    };
};
